import React from 'react'
import logo from '../assets/logo.png';
import styled from 'styled-components';
import { Button } from '@stats/playbook-components'
import { useAuth0 } from '@auth0/auth0-react';

const Page = styled.div`
  .unauthorizedPageWrapper {
    height: 254px;
    width: 400px;
    margin: 50px auto;
    vertical-align: center;
    background-color: white;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
  }

  .gradientAndLogo {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 90px;
    background-color: black;
    text-align: center;
  }

  .logo {
    margin: 16px;
  }

  .mainText {
    padding: 12px 0 12px 24px;
    font-size: 21px;
    font-weight: 300;
  }

  .secondaryText {
    font-size: 12px;
    padding-left: 24px;
  }

  .button {
    margin: auto;
  }
`;

const UnauthorizedPage: React.FC = () => {
  const { logout } = useAuth0()

  return (
    <Page className="unauthorizedPageWrapper">
      <div className='gradientAndLogo'>
        <img
          className='logo'
          src={logo}
          alt={'Stats Perform logo'}
          height={'58px'}
        />
      </div>
      <span className='mainText'>
        Unauthorized
      </span>
      <span className='secondaryText'>
        Not Authorized to Use Application
      </span>
      <Button
        className="button"
        variant={'primary'}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin + "/logout" }})}
      >
        Logout
      </Button>
    </Page>
  )
}

export default UnauthorizedPage
