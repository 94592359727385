import React from 'react';
import UnauthorizedPage from './UnauthorizedPage';
import { useAuth0 } from '@auth0/auth0-react';

const HomePage: React.FC = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <UnauthorizedPage />
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <>
      <h1>NLG Sports Dashboard</h1>
    </>
  );
}

export default HomePage;
