import React, { useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from '@auth0/auth0-react';

export const history = createBrowserHistory();

const App: React.FC = () => {
  const onRedirectCallback = useCallback((appState?: { returnTo?: string }) => {
    history.replace(appState?.returnTo || window.location.pathname);
  }, []);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID || ""}
      authorizationParams={{
        redirect_uri: window.location.origin + '/login',
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Router>
        <HomePage />
      </Router>
    </Auth0Provider>
  );
}

export default App;
